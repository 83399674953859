import React from 'react';
import LayoutEN from '../components/layout.es';
import CTAButton from '../components/ctabutton.component';
import Seo from '../components/seo.en';

import logoJobs from "../static/images/logo-jobs-partners.svg";
import emailImg from "../static/images/email-image.png";
import '../styles/jobs.scss';

export default function Jobs() {
  return (
    <LayoutEN>
      <Seo title="Work with us" />
      <section id="introJobs">
        <div className="introJobs-columns">
          <div id="introJobs-text" className="introJobs-column">
            <h1>Work with us</h1>
            <h2>Join our team</h2>
          </div>
        </div>
      </section>

      <div className="logo-jobs">
        <div className="logo-container">
          <img src={logoJobs} alt="Jobs partners" />
        </div>
      </div>

      <section id="mainJob" style={{ marginBottom: "1rem" }}>
        <div className="container">
          <h2>Software Engineer</h2>
          <h3>About the job</h3>
          <span>
            <p>
              At <strong>KuFlow</strong> we help any organization to design and
              execute processes involving people, robots and other applications.
              It is a <strong>SaaS </strong>
              platform that has just launched and we are looking for people who
              want to join us. If you are looking for a new challenge in a
              <strong> startup </strong> that is just starting, if you want to
              participate from a very early stage in an exciting project, talk
              to us.
            </p>
            <p>
              <strong>What will you do?</strong>
            </p>
            <ul>
              <li>
                You will create connectors that integrate KuFlow with other
                external platforms: Jira, Slack, Google tools, etc.
              </li>
              <li>
                You will participate in specific projects that help to
                successfully implement the platform in customers
              </li>
              <li>You will design RPAs with different solutions.</li>
              <li>
                You will have a say in the decisions that will shape the future
                of the platform.
              </li>
            </ul>

            <p>
              <strong>What do we expect from you?</strong>
            </p>
            <ul>
              <li>That you are excited about the project.</li>
              <li>
                You want to participate proactively in the development of the
                platform.
              </li>
              <li>
                That you are motivated by the idea of implementing the platform
                in customers and see how it improves their performance day by
                day.
              </li>
            </ul>
            <p>
              <strong>What do we need?</strong>
            </p>
            <ul>
              <li>
                People trained in software development (university degree or
                higher education).
              </li>
              <li>
                In general we are looking for generalists (backend and
                frontend), but we are open to profiles that only want to work in
                backend.
              </li>
              <li>
                Passion for technology and how it can add value to customers.
              </li>
              <li>
                Teamwork: ask for help and know how to provide it when needed.
              </li>
              <li>
                Ability to understand business needs and proactivity to suggest
                solutions collaborating with different points of view.
              </li>
              <li>A good level of English would be desirable.</li>
            </ul>
            <p>
              <strong>What do we offer?</strong>
            </p>
            <ul>
              <li>
                Salary: between 25K (if you have no experience) and 40K (several
                years of experience). 23 vacation days.
              </li>
              <li>23 vacation days.</li>
              <li>
                100% remote work, but always with the possibility to come to the
                office in Tenerife whenever you want.
              </li>
              <li>
                Flexible schedule (we only ask for common sense to match the
                rest of the team).
              </li>
              <li>Medical insurance with dental coverage.</li>
            </ul>
          </span>
          <CTAButton inverted mail mailaddress="jobs@kuflow.com" color="lblue">
            I want it
          </CTAButton>
          <div className="email-box">
            <span>Send us your resume to the following email: </span>
            <img src={emailImg} alt="Email" />
          </div>
        </div>
      </section>
    </LayoutEN>
  );
}
